<template>
	<LayoutLogin>
		<div>
			<loading-circle />
		</div>
	</LayoutLogin>
</template>

<script>
import LoadingCircle from '@/_srcv2/components/_shared/loading/LoadingCircle.vue'
export default {
	components: {
		LoadingCircle,
	},
	methods: {
		handleLoginEvent(data) {
			window.location.href = data.state.target || '/'
		},
	},
	created() {
		this.$auth.handleAuthentication()
	},
}
</script>

<style scoped></style>
